import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxList, DialogOverlay, DialogOverlayActions, Ellipsis, ListItem, useStatusOverlay } from '@fs/zion-ui'
import { NoticeLoading } from '@fs/zion-icon'
import { playlistsConfig } from '../../../config'
import { usePlaylistsContext } from '../../../providers'
import CreatePlaylistOverlay from './CreatePlaylistOverlay'
import CreatePlaylistButtonV2 from './CreatePlaylistButtonV2'
import PillButton from '../../../lib/buttons/PillButton'
import notifySentry from '../../../lib/helpers/sentryNotifier'
import { isArrayEqual } from '../../../lib/helpers'

const usePlaylistAddItemErrorHandling = () => {
  const [t] = useTranslation()
  const showStatusOverlay = useStatusOverlay()
  const { playlists } = usePlaylistsContext()

  return (playlistId, error) => {
    let message = t(
      'playlists.errors.generic.add-item',
      'We’re sorry, but there was an issue adding the session to your playlist. Please try again shortly or refresh your browser.'
    )
    if (error.response.status === 409) {
      message = t(
        'playlists.errors.playlist-full',
        'The {name} playlist has reached the maximum limit of {max} videos. Please delete some videos or create a new playlist.',
        {
          name: playlists.get(playlistId).name,
          max: playlistsConfig.sessionLimit,
        }
      )
    } else {
      notifySentry({ ...error.response, error })
    }

    showStatusOverlay({
      message,
      type: 'error',
      dismissable: true,
    })
  }
}

const usePlaylistRemoveItemErrorHandling = () => {
  const [t] = useTranslation()
  const showStatusOverlay = useStatusOverlay()

  return (err) => {
    showStatusOverlay({
      message: t(
        'playlists.errors.generic.remove-item',
        'We’re sorry, but there was an issue removing the session to your playlist. Please try again shortly or refresh your browser.'
      ),
      type: 'error',
      dismissable: true,
    })

    notifySentry({ ...err.response, err })
  }
}

export default function PlaylistManagementOverlay({ sessionId, target, onPlaylistUpdated, selectedPlaylist }) {
  const [t] = useTranslation()
  const { playlists, findPlaylistsWithItem, removeFromPlaylist, addToPlaylist } = usePlaylistsContext()
  const [containerPlaylists, setContainerPlaylists] = useState(new Set())
  const [isCreatingNewPlaylist, setCreateNewPlaylist] = useState(false)
  const showStatusOverlay = useStatusOverlay()
  const [loading, setLoading] = useState(true)

  useMemo(() => {
    findPlaylistsWithItem({ itemId: sessionId }).then((results) => {
      const ids = results.map((p) => p.id)
      setContainerPlaylists(new Set(ids))
      setLoading(false)
    })
  }, [findPlaylistsWithItem, sessionId])

  const handleAddItemError = usePlaylistAddItemErrorHandling()
  const handleRemoveItemError = usePlaylistRemoveItemErrorHandling()

  const selectionChangedCallback = useCallback(
    (selectedIds) => {
      setContainerPlaylists((old) => {
        const latest = new Set(selectedIds)
        const newIds = [...latest.values()]
        const oldIds = [...old.values()]

        if (isArrayEqual(oldIds, newIds)) {
          return old
        }

        const playlistsToAdd = selectedIds.filter((id) => !old.has(id))
        const playlistsToRemove = oldIds.filter((id) => !latest.has(id))

        // Batch remove from playlists
        if (playlistsToRemove.length > 0) {
          Promise.all(
            playlistsToRemove.map((playlistId) =>
              removeFromPlaylist({ playlistId, itemId: sessionId }).catch((err) =>
                handleRemoveItemError(playlistId, err)
              )
            )
          )
        }

        // Batch add to playlists
        if (playlistsToAdd.length > 0) {
          Promise.all(
            playlistsToAdd.map((playlistId) => {
              const playlist = playlists.get(playlistId)
              const needsWarningMessage = playlist.size + 1 >= playlistsConfig.sessionLimit
              return addToPlaylist({ playlistId, items: [sessionId] })
                .then(() => {
                  if (needsWarningMessage) {
                    showStatusOverlay({
                      message: t(
                        'playlists.alerts.size-warning',
                        'The {name} playlist is getting close to the maximum limit of {max} videos.',
                        { name: playlist.name, max: playlistsConfig.sessionLimit }
                      ),
                      type: 'warning',
                      dismissable: true,
                    })
                  }
                })
                .catch((err) => handleAddItemError(playlistId, err))
            })
          )
        }
        if (onPlaylistUpdated) {
          if (playlistsToAdd.length > 0) {
            onPlaylistUpdated(playlistsToAdd)
          } else if (playlistsToAdd.length === 0 && playlistsToRemove.length === oldIds.length) {
            onPlaylistUpdated(playlistsToAdd)
          } else if (selectedPlaylist) {
            onPlaylistUpdated(selectedPlaylist)
          }
        }

        return latest
      })
    },
    [
      onPlaylistUpdated,
      removeFromPlaylist,
      sessionId,
      handleRemoveItemError,
      playlists,
      addToPlaylist,
      showStatusOverlay,
      t,
      handleAddItemError,
      selectedPlaylist,
    ]
  )

  if (isCreatingNewPlaylist) {
    return (
      <CreatePlaylistOverlay
        {...target}
        sessionId={sessionId}
        onPlaylistCreated={(newPlaylist) => {
          setContainerPlaylists((old) => new Set([...old, newPlaylist.id]))
          setCreateNewPlaylist(false)
          onPlaylistUpdated()
        }}
      />
    )
  }

  const canCreatePlaylists = [...playlists.values()].filter((p) => !p.parent).length < playlistsConfig.playlistLimit

  return (
    <DialogOverlay
      {...target}
      size="sm"
      title={t('playlists.overlay.heading', 'Add to...')}
      footer={
        <DialogOverlayActions>
          {canCreatePlaylists && <CreatePlaylistButtonV2 onClick={() => setCreateNewPlaylist(true)} />}
          <PillButton emphasis="high" onClick={() => target.close()} data-testid="playlists-close">
            {t('common-ui:close.action', 'Close')}
          </PillButton>
        </DialogOverlayActions>
      }
    >
      {loading && <NoticeLoading />}
      {!loading && containerPlaylists && (
        // NOTE: CheckboxList is not as stable as I would like. Maybe switch with a List of Checkboxes like the search page
        <CheckboxList onSelectionChange={selectionChangedCallback} initialSelection={[...containerPlaylists.values()]}>
          {[...playlists.values()]
            .filter((p) => !p.parent)
            .map((playlist) => {
              const full = !playlist.default && playlist.size >= playlistsConfig.sessionLimit

              let secondaryText = t('playlists.playlist.size', '{ playlistSize } videos', {
                playlistSize: playlist.size || 0,
              })
              if (full) {
                secondaryText = t('playlists.playlists.size-status', '{ playlistSize } videos (FULL)', {
                  playlistSize: playlist.size,
                })
              }

              return (
                <ListItem
                  data-testid={`${playlist.default ? 'watch-later' : playlist.name}-playlist`}
                  dense
                  disabled={full}
                  primaryText={
                    <Ellipsis>
                      <strong>{playlist.name}</strong>
                    </Ellipsis>
                  }
                  secondaryText={secondaryText}
                  key={playlist.id}
                  name={playlist.id}
                  linkName={`Add to ${playlist.default ? 'Default' : 'Other'} Playlist`}
                />
              )
            })}
        </CheckboxList>
      )}
    </DialogOverlay>
  )
}
