export function addAttribute(name, value) {
  if (typeof value !== 'undefined') {
    if (value?.length > 0) {
      return `${name}: "${sanitize(value)}"`
    }

    return `${name}: ""`
  }

  return ''
}

export function replaceCreator(replacement) {
  if (replacement?.addId && replacement?.removeId && replacement?.addId !== replacement?.removeId) {
    return `replaceCreatorInput: {
      ${addAttribute('id_creator_to_add', replacement.addId)}, 
      ${addAttribute('id_creator_to_remove', replacement.removeId)}
    }`
  }

  return ``
}

export function stripHtml(value) {
  if (value?.length) {
    return value.replace(/<[^>]*>?/gm, '')
  }

  return value
}

export function sanitize(value) {
  if (value?.length) {
    return value.replace(/["]+/g, '\\"')
  }

  return value
}

export function brEncodeAttribute(value) {
  if (value?.length) {
    return value.replace(/(\r\n|\r|\n)/g, '<br>')
  }

  return value
}

export function returnEncodeAttribute(value) {
  if (value?.length) {
    return value.replace(/(<br>|<br\/>)/g, '\r')
  }

  return value
}

export function escapeBackslash(value) {
  if (value?.length) {
    return value.replace(/\\/g, '\\\\')
  }

  return value
}

export function getEncodedAttribute(name, value) {
  if (value?.length > 0) {
    return `${name}: "${encodeURI(value)}"`
  }
  return ``
}
export function addEncodedAttribute(name, value) {
  if (typeof value !== 'undefined') {
    if (value?.length > 0) {
      return `${name}: "${encodeURI(value)}"`
    }

    return `${name}: ""`
  }

  return ''
}

export function getSocialAccounts(socialAccounts) {
  if (typeof socialAccounts !== 'undefined') {
    if (socialAccounts?.length > 0) {
      return `socialAccounts: [${socialAccounts.map(
        (socialAccount) => `{
        platform: ${socialAccount.platform},
        ${addAttribute('urlOrHandle', socialAccount.urlOrHandle)}
      }`
      )}]`
    }

    return `socialAccounts: []`
  }

  return ``
}

export function getTarget(cmsId, locale) {
  const id = addAttribute('id', cmsId)
  const localeAttrib = addAttribute('locale', locale)
  if (localeAttrib?.length > 0) {
    return `_target: { ${id}, ${localeAttrib} },`
  }

  return `_target: { ${id} },`
}

export function getVisibilityRequest(statusValues) {
  if (statusValues?.length) {
    return `visibilityRequest: {status: [${statusValues.map((status) => `${status}`)}]}`
  }

  return `visibilityRequest: {status: []}`
}

// export function getCreatorTypes(creatorTypes) {
//   if (creatorTypes?.length) {
//     return `creatorTypes: [${creatorTypes.map((type) => `"${type}"`)}]`
//   }
//   return ``
// }

export function getIds(ids, key = 'ids') {
  if (ids?.length) {
    return `${key}: [${ids.map((id) => `"${id}"`)}]`
  }
  return ``
}

export function getCreatorIds(creators) {
  if (creators?.length) {
    return `creators: [${creators.map((creatorId) => `"${creatorId}"`)}]`
  }
  return ``
}

export function getCreatorTypes(creatorTypes) {
  if (creatorTypes?.length) {
    return `creatorTypes: [${creatorTypes.map((type) => `"${type}"`)}]`
  }

  return ``
}
export function getEnumAttribute(name, value) {
  if (typeof value !== 'undefined') {
    if (value?.length > 0) {
      // eslint-disable-next-line no-useless-concat
      return `${name}:` + `${value}`
    }

    return `${name}: ""`
  }

  return ``
}

export function getContactInformation(creator) {
  if (typeof creator?.contactInformation !== 'undefined') {
    return `contactInformation: {
        ${addAttribute('country', creator.contactInformation.country)}
        ${addAttribute('email', creator.contactInformation.email)}
      }`
  }

  return ''
}

export function getWorkflow(transition) {
  if (transition) {
    return `_workflow: {action: TRANSITION, targetState: {next: true}}`
  }

  return ''
}

export function getPaginationData(paginationData) {
  if (paginationData) {
    return `paginationData { ${[...paginationData]} }`
  }

  return ''
}

export function getTargetHack(cmsObj) {
  // If we have a session id we are doing an update.  Otherwise, it is a create
  let hack
  if (cmsObj?.id) {
    hack = getTarget(cmsObj.id, cmsObj.locale)
  } else {
    hack = addAttribute('contentLocale', cmsObj.locale)
  }

  return hack
}
