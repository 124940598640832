import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react'
import { usePermissionsAccess, useStableObject } from '../../../hooks'

const DashboardPermissionsContext = createContext()
export const useDashboardPermissionsContext = () => useContext(DashboardPermissionsContext)

export const DashboardPermissionsProvider = ({ children }) => {
  const permissions = usePermissionsAccess()
  const [tempPermissions, setPermissions] = useState(permissions)

  // This can potentially cause race condition when the server returns the permissions state it may override the toggled state.
  const updatePermissions = useCallback((name, checked) => {
    setPermissions((oldPermissions) => {
      return { ...oldPermissions, [name]: checked }
    })
  }, [])

  const stablePermissions = useStableObject(permissions)
  useEffect(() => {
    setPermissions(stablePermissions)
  }, [stablePermissions])

  const { hasSuperAdminAccess, hasContentAdminAccess } = tempPermissions

  const hasDashboardAccess = Object.values(tempPermissions).some(Boolean)
  const hasDashboardAuthorizationAccess = hasContentAdminAccess || hasSuperAdminAccess

  const contextValue = useMemo(
    () => ({
      permissions: { ...tempPermissions, hasDashboardAccess, hasDashboardAuthorizationAccess },
      updatePermissions,
    }),
    [tempPermissions, hasDashboardAccess, hasDashboardAuthorizationAccess, updatePermissions]
  )

  return <DashboardPermissionsContext.Provider value={contextValue}>{children}</DashboardPermissionsContext.Provider>
}
