import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { escapeBackslash } from '../helpers'
import VideoMutation from './fragments/VideoMutation'
import { VideoDetail } from './fragments/VideoDetail'

const { defaults } = brightspotConfig.query

function mutateVideo() {
  return gql(`
  ${VideoMutation}
  mutation MutateVideo(
    $creators: [ID],
    $exhibitors: [ID],
    $headline: String,
    $subHeadline: String,
    $contentLocale: String,
    $target: MutationTargetInput,
    $thumbnail: ID,
    $thumbnailImage_crop: Boolean = false,
    $thumbnailImage_height: Int = ${defaults.imageSize},
    $thumbnailImage_width: Int = ${defaults.imageSize},
    $videoProvider: VideoProviderInput) {
      VideoMutation(
        _target: $target,
        creators: $creators,
        exhibitors: $exhibitors,
        headline: $headline,
        subHeadline: $subHeadline,
        thumbnail: $thumbnail,
        contentLocale: $contentLocale
        videoProvider: $videoProvider ) {
          ...VideoMutation
      }
  }
  `)
}

function deleteVideoMutation() {
  return gql(`
    ${VideoDetail}
    mutation MutateVideo(
      $creators: [ID],
      $exhibitors: [ID],
      $target: MutationTargetInput,
      $videoProvider: VideoProviderInput
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $workflow: WorkflowInput = { action: DELETE }) {
      VideoMutation(
        _target: $target, 
        creators: $creators,
        exhibitors: $exhibitors,
        videoProvider: $videoProvider
        _workflow: $workflow) {
        result {
          ...VideoDetail
        }
      }
    }
  `)
}

// export async function getVideo(client, videoId, locale) {
//   const variables = {
//     target: { id: videoId, locale },
//   }
//   const response = await client.mutate({ mutation: getVideoMutation(), variables })
//   return response?.data?.VideoMutation?.result
// }

export async function createVideo(client, video) {
  const variables = {
    headline: escapeBackslash(video.headline),
    subHeadline: escapeBackslash(video.subHeadline),
    thumbnail: video.videoThumbnailId,
  }
  if (video.creatorId) {
    variables.creators = video.creatorId
  }
  if (video.exhibitorId) {
    variables.exhibitors = video.exhibitorId
  }
  // URL for YT or Webinar
  if (video.identifier) {
    variables.videoProvider = { identifier: video.identifier, provider: video.provider }
  }
  // If we have a session id we are doing an update.  Otherwise, it is a create
  if (video.id) {
    variables.target = { id: video.id, locale: video.locale }
  } else {
    variables.contentLocale = video.locale ? video.locale : ''
  }
  const response = await client.mutate({ mutation: mutateVideo(), variables })
  return response?.data?.VideoMutation?.result
}

export async function updateVideo(client, video) {
  return createVideo(client, video)
}

export async function deleteVideo(client, video) {
  const variables = {
    target: { id: video.id, locale: video.locale },
  }
  if (video.creatorId) {
    variables.creators = video.creatorId
  }
  if (video.exhibitorId) {
    variables.exhibitors = video.exhibitorId
  }
  if (video.identifier) {
    variables.videoProvider = { identifier: video.identifier, provider: video.provider }
  }

  const response = await client.mutate({ mutation: deleteVideoMutation(), variables })
  return response?.data?.VideoMutation?.result
}
