import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { usePreferredColorScheme } from './utils/usePreferredColorScheme'
import { useLocalStorage } from '../../hooks'
import { THEME } from './config/theme'

const ThemeContext = createContext()
export const useThemeContext = () => useContext(ThemeContext)

export const ThemeProvider = ({ children }) => {
  const [storedValue, setStoredValue] = useLocalStorage('rootstech.theme', '')
  const [theme, setTheme] = usePreferredColorScheme(storedValue)

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT
      setStoredValue(newTheme)
      return newTheme
    })
  }, [setTheme, setStoredValue])

  const dayThemeFlag = useFeatureFlag('rootstech_day_theme')
  const value = useMemo(
    () => ({
      theme: dayThemeFlag.isOn ? theme : THEME.DARK,
      toggleTheme,
    }),
    [dayThemeFlag.isOn, theme, toggleTheme]
  )
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
