import { IconButton, ListItem } from '@fs/zion-ui'
import { ControlDark, ControlBrightnessMore } from '@fs/zion-icon'
import React from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTranslation } from 'react-i18next'
import { useThemeContext } from '../../../providers'
import { useIsMdWidth } from '../../../hooks'
import { navMenuButtonCss } from '../NavButtonHack'

export const ThemeButton = ({ onClick }) => {
  const [t] = useTranslation()
  const { theme, toggleTheme } = useThemeContext()
  const isMdWidth = useIsMdWidth()

  const dayThemeFlag = useFeatureFlag('rootstech_day_theme')
  if (!dayThemeFlag.isOn) {
    return null
  }

  const Icon = theme === 'day' ? ControlBrightnessMore : ControlDark
  if (isMdWidth) {
    const onMdClick = () => {
      if (onClick) {
        onClick()
      }
      toggleTheme()
    }
    return (
      <ListItem
        startElement={<ListItem.Icon Icon={Icon} />}
        onClick={onMdClick}
        primaryText={t('nav.theme.label', 'Change theme')}
      />
    )
  }
  return (
    <div css={navMenuButtonCss}>
      <IconButton Icon={Icon} onClick={toggleTheme} title={t('nav.theme.label', 'Change theme')} />
    </div>
  )
}
