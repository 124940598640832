import { useEffect, useState } from 'react'
import { THEME } from '../config/theme'

const DARK_SCHEME_QUERY = '(prefers-color-scheme: dark)'

export const usePreferredColorScheme = (override) => {
  const [scheme, setScheme] = useState(override)

  useEffect(() => {
    if (!window.matchMedia) return null

    const mediaQuery = window.matchMedia(DARK_SCHEME_QUERY)

    const handleChange = (event) => {
      const newTheme = event.matches ? THEME.DARK : THEME.LIGHT
      if (!override) {
        setScheme(newTheme)
      }
    }

    handleChange(mediaQuery)
    mediaQuery.addEventListener('change', handleChange)

    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [override])

  return [scheme, setScheme]
}
